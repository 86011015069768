import _uniq from 'lodash/uniq'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _flow from 'lodash/flow'
import _filter from 'lodash/filter'
import _parseISO from 'date-fns/parseISO'
import _startOfDay from 'date-fns/startOfDay'

import { getImageURL, toPlainText } from 'utils/sanity'
import {
  ContextI18n,
  getDownloadLink,
  getInternalLink,
  SanityReference,
} from 'utils/helpers'
import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export type SingleProgram = NonNullable<
  Queries.ReadProgramQuery['sanityProgram']
>

export const BasePath = '/open-programs'

export const query = graphql`
  fragment SanityProgramDetails on SfProgramDetails {
    id
    location
    language
    duration
    format
    terms {
      id
      name
      start_date
      end_date
      application_deadline
      courses_included
      deadline_early_bird
      deadline_super_early_bird
      fee_additional_course
      fee_early_bird
      fee_regular
      fee_super_early_bird
      courses {
        end
        id
        location
        mandatory
        start
        title
      }
    }
  }
`

export const palette = {
  variant: 'secondaryAlpha',
  accent: 'secondaryBeta',
}

export const useResolveProgramDetails = (
  program: SanityReference | SingleProgram
): Queries.SanityProgramDetailsFragment | undefined => {
  // we need this as long as there are _raw fields used for Structured Content.
  const data = useStaticQuery<Queries.ResolveProgramDetailsQuery>(graphql`
    query ResolveProgramDetails {
      allSanityProgram {
        nodes {
          id
          _id
          details {
            ...SanityProgramDetails
          }
        }
      }
    }
  `)

  const details = useMemo(() => {
    if ('details' in program && program.details) {
      return program.details ?? undefined
    }

    if ('id' in program && program.id) {
      const res = data.allSanityProgram.nodes.find(
        (n) => n.id === program.id
      )?.details
      if (res) {
        return res
      }
    }

    if ('_id' in program && program._id) {
      return (
        data.allSanityProgram.nodes.find((n) => n._id === program._id)
          ?.details ?? undefined
      )
    }

    if ('_ref' in program && program._ref) {
      return (
        data.allSanityProgram.nodes.find((n) => n._id === program._ref)
          ?.details ?? undefined
      )
    }

    return undefined
  }, [program])

  return useMemo(() => {
    if (!details) {
      return undefined
    }

    const today = _startOfDay(new Date())

    return {
      ...details,
      terms: details.terms
        .filter((t) => {
          if (t.application_deadline) {
            return _parseISO(t.application_deadline) >= today
          }

          if (t.start_date) {
            return _parseISO(t.start_date) >= today
          }

          if (details.terms.length === 1) {
            return true
          }

          if (t?.courses[0]?.start) {
            return _parseISO(t.courses[0].start) >= today
          }
        })
        .map((t) => ({
          ...t,
          courses: t.courses.filter((c) => {
            if (!c.start) {
              return true
            }

            return _parseISO(c.start) >= today
          }),
        })),
    }
  }, [details])
}

const useNextTerm = (program: SanityReference | SingleProgram) => {
  const details = useResolveProgramDetails(program)

  return useMemo(() => {
    if (!details) {
      return undefined
    }

    if (details.terms.length === 0) {
      return undefined
    }

    return _sortBy(details.terms, (t) => t.start_date ?? '2100-12-31')[0]
  }, [details])
}

export const resolveLocalizedValue = (program: any, key: string): any => {
  if (!(key in program && program[key])) {
    return undefined
  }

  if (typeof program[key] === 'string') {
    return program[key]
  }

  if (typeof program[key] === 'object' && 'localized' in program[key]) {
    return program[key].localized
  }
}

export const useResolveLocalizedValue = (program: any, key: string): any => {
  return useMemo(() => {
    return resolveLocalizedValue(program, key)
  }, [program, key])
}

export const useNextTermRun = (
  program: SanityReference | SingleProgram,
  locale: string
) => {
  const term = useNextTerm(program)

  return useMemo(() => {
    if (!term) {
      return undefined
    }

    const today = _startOfDay(new Date())

    const fees =
      term.fee_regular &&
      term.fee_regular.toLocaleString(locale === 'en' ? 'en-US' : 'de-DE', {
        style: 'currency',
        currency: 'EUR',
      })

    const feeEarlyBird =
      term.fee_early_bird &&
      term.fee_early_bird.toLocaleString(locale === 'en' ? 'en-US' : 'de-DE', {
        style: 'currency',
        currency: 'EUR',
      })

    const earlyBirdDate =
      !!term.deadline_early_bird && _parseISO(term.deadline_early_bird)
    const earlyBirdActive = earlyBirdDate && earlyBirdDate >= today

    const feeSuperEarlyBird =
      term.fee_super_early_bird &&
      term.fee_super_early_bird.toLocaleString(
        locale === 'en' ? 'en-US' : 'de-DE',
        { style: 'currency', currency: 'EUR' }
      )
    const superEarlyBirdDate =
      !!term.deadline_super_early_bird &&
      _parseISO(term.deadline_super_early_bird)
    const superEarlyBirdActive =
      superEarlyBirdDate && superEarlyBirdDate >= today

    const applicationDeadlineParsed =
      !!term.application_deadline && _parseISO(term.application_deadline)

    const startDate = term.start_date
      ? _parseISO(term.start_date)
      : term.courses[0]?.start
      ? _parseISO(term.courses[0].start)
      : undefined

    const deadlinePassed =
      (startDate && startDate <= today) ||
      (applicationDeadlineParsed && applicationDeadlineParsed < today)

    const locations = _uniq(
      term.courses
        .map((c) => c.location)
        .filter((l) => !l || l.trim().length === 0)
    )

    return {
      id: term.id,
      startDate: startDate,
      endDate: term.end_date
        ? _parseISO(term.end_date)
        : term.courses[term.courses.length - 1]?.end
        ? _parseISO(term.courses[term.courses.length - 1].end)
        : undefined,
      locations,
      fees,
      feeEarlyBird,
      earlyBirdDate,
      earlyBirdActive,
      feeSuperEarlyBird,
      superEarlyBirdDate,
      superEarlyBirdActive,
      applicationDeadlineParsed,
      deadlinePassed,
      courses: term?.courses,
      coursesIncluded: term?.courses_included,
      feeAdditionalCourse: term?.fee_additional_course,
    }
  }, [])
}

export const buildCategoryProgramMap = (
  categories: Queries.SanityCategories,
  programs: Array<Pick<SingleProgram, 'title' | 'category'>>
) => {
  return _flow(
    (data) => _sortBy(data, ['sort', 'title']),
    (data) =>
      _map(data, (category) => ({
        id: category.id,
        title: category.title,
        programs: _flow(
          (data) =>
            _filter(data, (program) => program.category?.id === category.id),
          (data) => _sortBy(data, ['title'])
        )(programs),
      }))
  )(categories)
}

export const getApplicationURL = (
  program: Pick<
    SingleProgram,
    'onlineApplication' | 'externalApplication' | 'applicationForm' | 'details'
  >,
  nextRun?: ReturnType<typeof useNextTermRun>
) => {
  if (program.onlineApplication) {
    if (!nextRun) {
      return
    }

    const now = new Date()
    if (nextRun.deadlinePassed) {
      return
    }

    if (nextRun.startDate && nextRun.startDate < now) {
      return
    }

    if (program.details?.id) {
      return `https://application-center.whu.edu/onboarding/programs/${program.details.id}`
    }

    return 'https://application-center.whu.edu/onboarding/programs/'
  }

  if (program.externalApplication) {
    return program.externalApplication
  }

  if (program.applicationForm?.asset?.url) {
    let title = program.applicationForm?.asset?.originalFilename

    if (!title) {
      const slug = getInternalLink(program as any, 'en')
      title = `whu_${slug}_application_form.pdf`
    }

    return `${program.applicationForm.asset.url}?dl=${encodeURIComponent(
      title
    )}`
  }

  return undefined
}

const localeFieldToString = (
  field: any,
  i18n: ContextI18n,
  fallback = true
): string | undefined => {
  return field?.[i18n.language] ?? (fallback ? field?.en : undefined)
}

export const useSeo = (program: SingleProgram, i18n: ContextI18n) => {
  const nextRun = useNextTerm(program)

  return useMemo(() => {
    let offers = undefined
    if (nextRun) {
      offers = {
        '@type': 'Offer',
        priceCurrency: 'EUR',
        price: nextRun.fee_regular,
      }
    }

    return {
      name: program.title,
      description: localeFieldToString(program?.meta?.description, i18n),
      overrides: {
        offers,
        provider: {
          '@type': 'CollegeOrUniversity',
          name: 'WHU - Otto Beisheim School of Management',
          url: 'https://ee.whu.edu/',
        },
      },
    }
  }, [program, nextRun, i18n])
}

export const useOpenGraph = (program: SingleProgram, i18n: ContextI18n) => {
  const intro = program._rawIntro[i18n.language] ?? program._rawIntro.en

  const imageURL = getImageURL(program, 'hero.asset._id')
  const imageAlt = program?.hero?.alt?.localized
  return {
    title:
      localeFieldToString(program?.meta?.title, i18n, false) ?? program.title!,
    description:
      localeFieldToString(program?.meta?.description, i18n) ??
      toPlainText(intro),
    openGraph: {
      title: program.title!,
      description: toPlainText(intro),
      images: imageURL
        ? [
            {
              width: 800,
              height: 600,
              url: imageURL,
              alt: imageAlt ?? program.title!,
            },
          ]
        : undefined,
    },
  }
}

const makeMailLink = (
  email: string | undefined | null,
  subject?: Queries.SanityLocaleString | undefined | null,
  body?: Queries.SanityLocaleText | undefined | null
) => {
  if (!email) {
    return undefined
  }

  const params = []

  const _subject = subject?.localized?.trim()
  if (_subject && _subject !== '') {
    params.push('subject=' + encodeURIComponent(_subject))
  }

  const _body = body?.localized?.trim()
  if (_body && _body !== '') {
    params.push('body=' + encodeURIComponent(_body))
  }

  let url = `mailto:${email}`

  if (params.length > 0) {
    url = `${url}?${params.join('&')}`
  }

  return url
}

export const makeCallScheduleLink = (
  program: SingleProgram,
  term?: ReturnType<typeof useNextTermRun>
) => {
  if (!program.callSchedule) {
    return undefined
  }

  if (program.callScheduleType === 'mail') {
    return makeMailLink(
      program.callScheduleEmail,
      program.callScheduleEmailSubject,
      program.callScheduleEmailBody
    )
  }

  let title = program.title?.trim()

  if (!title || !program.callScheduleUrl) {
    return undefined
  }

  title = encodeURIComponent(title)

  return `${program.callScheduleUrl}?subject="${title}"&SO_ExecEd_Which_program_are_you_interested_in="${title}"&programId=${program.details?.id}&termId=${term?.id}`
}

export const makeBrochureLink = (
  program: SingleProgram,
  onClick: () => any
) => {
  if (program.brochureType === 'none') {
    return undefined
  }

  if (program.brochureType === 'mail') {
    return {
      type: 'external',
      href: makeMailLink(
        program.brochureEmail,
        program.brochureEmailSubject,
        program.brochureEmailBody
      ),
      onClick: onClick,
    }
  }

  if (program.brochureType === 'file') {
    return {
      type: 'external',
      href: getDownloadLink(program.brochure as any),
      onClick: onClick,
    }
  }

  if (program.brochureType === 'url') {
    return {
      type: 'external',
      href: program.brochure_url?.trim(),
      onClick: onClick,
    }
  }

  if (program.brochureType === 'pardot') {
    return {
      type: 'internal',
      to: `/open-programs/${program.slug}/brochure`,
      onClick: onClick,
    }
  }

  return undefined
}
